<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
    class="leasing-comparison"
  >
    <div class="w-crumbs-href">
      <span>您现在所在的位置：</span>
      <router-link
        tag="span"
        class="link-item"
        :to="{ path: '/' }"
      >工作台</router-link>
      <i class="el-icon-arrow-right"></i>
      <router-link
        tag="span"
        class="link-item"
        :to="{ path: '/work' }"
      >招商规划管理</router-link>
      <i class="el-icon-arrow-right"></i>
      <router-link
        tag="span"
        class="link-item"
        :to="{ path: '/work' }"
      >租赁决策概览</router-link>
      <i class="el-icon-arrow-right"></i>
      <span style="color: #FFF">{{$route.meta.title}}</span>
      <div class="fr">
        <div class="w-input-sty project-select">
          <el-select
            popper-class="w-block-select-down yellow-select-panel"
            class="select-extra"
            v-model="filtroProject"
            size="small"
            placeholder="请选择"
            filterable
            :disabled="true"
          >
            <el-option
              v-for="item in projectOptions"
              :key="item.id"
              :label="item.projectName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="common-shadow-panel vs-header d-flex space-between">
      <div class="this-project">
        <h3>{{originLeasingTitle}}</h3>
        <ul v-if="overview.length > 0">
          <li>
            <h4>{{overview[0].rent}}</h4>
            <p>首阶段租金目标(元)</p>
            <p>复合增长率：{{overview[0].rentGrowthRate}}</p>
          </li>
          <li>
            <h4>{{overview[0].cost}}</h4>
            <p>首阶段{{user.topuid === 6467 ? '物业管理费目标' : '综合管理费目标'}}(元)</p>
            <p>复合增长率：{{overview[0].costGrowthRate}}</p>
          </li>
          <li>
            <h4>{{overview[0].income}}</h4>
            <p>首阶段收入目标(元)</p>
            <p>复合增长率：{{overview[0].incomeGrowthRate}}</p>
          </li>
        </ul>
        <p v-if="overview.length > 0">应用楼宇：{{overview[0].buildings}}<span style="margin-left: 12px">生效时间：{{overview[0].effectDate}}</span></p>
      </div>
      <i class="vs-icon"></i>
      <div class="compare-project">
        <div class="native-input-solid">
          <el-select
            ref="projectSelector"
            class="input-solid"
            popper-class="w-block-select-down yellow-select-panel"
            v-model="leasingDecision"
            filterable
            size="small"
            placeholder="请选择"
            @change="leasingChange"
          >
            <el-option
              v-for="item in leasingDecisionList"
              :key="item.decisionId"
              :label="item.decisionName"
              :value="item.decisionId"
            >
            </el-option>
          </el-select>
        </div>
        <ul v-if="overview.length > 1">
          <li>
            <h4>{{overview[1].rent}}</h4>
            <p>首阶段租金目标(元)</p>
            <p>复合增长率：{{overview[1].rentGrowthRate}}</p>
          </li>
          <li>
            <h4>{{overview[1].cost}}</h4>
            <p>首阶段{{user.topuid === 6467 ? '物业管理费目标' : '综合管理费目标'}}(元)</p>
            <p>复合增长率：{{overview[1].costGrowthRate}}</p>
          </li>
          <li>
            <h4>{{overview[1].income}}</h4>
            <p>首阶段收入目标(元)</p>
            <p>复合增长率：{{overview[1].incomeGrowthRate}}</p>
          </li>
        </ul>
        <p v-if="overview.length > 1">应用楼宇：{{overview[1].buildings}}<span style="margin-left: 12px">生效时间：{{overview[1].effectDate}}</span></p>
      </div>
    </div>
    <div class="common-shadow-panel">
      <h2 class="w-title-gradients">品牌策略对比</h2>
      <div style="padding: 60px 13px 32px 53px">
        <div id="brandContrast"></div>
      </div>
    </div>
    <div class="common-shadow-panel">
      <h2 class="w-title-gradients">业态策略对比</h2>
      <div class="chart-data">
        <div id="commercialContrast"></div>
      </div>
    </div>
    <div class="common-shadow-panel">
      <h2 class="w-title-gradients">主次力店策略对比</h2>
      <div
        style="padding-bottom: 60px"
        v-if="mainSecondlData[0].data.length === 0 && mainSecondlData[1].data.length === 0 && mainSecondlData[2].data.length === 0 && mainSecondlData[3].data.length === 0"
      >
        <div class="no-recommend-brand">
          <img src="@/assets/images/public/not-plandata.png">
          <p>暂无相关数据</p>
        </div>
      </div>
      <div
        class="chart-data"
        v-else
      >
        <div id="mainSecondContrast"></div>
      </div>
    </div>
    <div class="common-shadow-panel">
      <h2 class="w-title-gradients">租金计划对比</h2>
      <p class="type-select">
        <el-radio-group
          v-model="planContrastType"
          @change="planContrastChange"
        >
          <el-radio :label="1">按楼层</el-radio>
          <el-radio :label="2">按业态</el-radio>
        </el-radio-group>
      </p>
      <div
        class="chart-data"
        style="padding-top: 23px"
      >
        <div class="d-flex space-between">
          <div id="planContrast"></div>
          <div class="vs-list">
            <ul v-if="vsList.length === 3">
              <li
                v-for="(item, inx) in vsList[0]"
                :key="inx"
              >
                <p class="d-flex space-between vs-content">
                  <span style="color: #FFF; font-size: 16px">{{vsList[1][inx] + '元/月/㎡'}} </span>
                  <span style="text-align: center; line-height: 21px">{{item}}</span>
                  <span style="color: #FFF; font-size: 16px; text-align: right">{{vsList[2][inx] + '元/月/㎡'}}</span>
                </p>
                <div class="d-flex vs-progress">
                  <el-progress
                    class="left-progress"
                    :percentage="vsDataShow[0] ? !vsList[1][inx] ? 0 : vsList[1][inx] / (vsList[1][inx] + vsList[2][inx]) * 100 : 0"
                    :stroke-width="12"
                    color="#00C567"
                    :show-text="false"
                  ></el-progress>
                  <el-progress
                    class="right-progress"
                    :percentage="vsDataShow[1] ? !vsList[2][inx] ? 0 : vsList[2][inx] / (vsList[1][inx] + vsList[2][inx]) * 100 : 0"
                    :stroke-width="12"
                    color="#00D7D5"
                    :show-text="false"
                  ></el-progress>
                </div>
              </li>
            </ul>
            <p class="vs-bottom d-flex">
              <span
                :class="!vsDataShow[0] ? 'unshow' : ''"
                @click="vsDataShow[0] = !vsDataShow[0]; $forceUpdate()"
              ><i></i>租决1</span>
              <span
                :class="!vsDataShow[1] ? 'unshow' : ''"
                @click="vsDataShow[1] = !vsDataShow[1]; $forceUpdate()"
              ><i></i>租决2</span>
            </p>
          </div>
        </div>
        <div class="constitute d-flex">
          <div>
            <p class="constitute-title">租决1楼层业态构成</p>
            <div
              id="constitute1"
              v-if="!constitute1NotData"
            ></div>
            <div
              style="padding-top: 160px; height: 630px"
              v-else
            >
              <div class="no-recommend-brand">
                <img src="@/assets/images/public/not-plandata.png">
                <p>暂无相关数据</p>
              </div>
            </div>
          </div>
          <div>
            <p class="constitute-title">租决2楼层业态构成</p>
            <div
              id="constitute2"
              v-show="!constitute2NotData"
            ></div>
            <div
              style="padding-top: 160px; height: 630px"
              v-show="constitute2NotData"
            >
              <div class="no-recommend-brand">
                <img src="@/assets/images/public/not-plandata.png">
                <p>暂无相关数据</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="common-shadow-panel">
      <h2 class="w-title-gradients">落位计划对比</h2>
      <div class="fall-plan-select w-input-sty">
        <el-select
          class="input-solid"
          popper-class="w-block-select-down yellow-select-panel"
          v-model="fallPlan"
          filterable
          size="small"
          placeholder="请选择"
          @change="fallPlanChange"
        >
          <el-option
            v-for="item in fallPlanList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div
        class="chart-data"
        style="padding-top: 23px"
      >
        <div
          id="fallPlanContrast"
          v-if="!fallPlanNotData"
        ></div>
        <div v-else>
          <div class="no-recommend-brand">
            <img src="@/assets/images/public/not-plandata.png">
            <p>暂无相关数据</p>
          </div>
        </div>
      </div>
      <div class="other-data">
        <h3 class="second-title">品牌标签画像对比</h3>
        <div class="cloud-box d-flex">
          <div
            v-for="(item, inx) in words"
            :key="inx"
            style="width: 46%"
          >
            <WordCloud
              v-if="wordsHasdata[inx]"
              :data="item"
              style="width: 100%; height: 320px; background: #1E1E23"
              :rotate="{from: 0, to: 0, numOfOrientation: 0 }"
              :fontSize="[14, 28]"
              :showTooltip="false"
              font="Arial"
              :color="['#F79306', '#00D7D5', '#37ABFD', '#10DD71']"
            ></WordCloud>
            <div
              style="padding-bottom: 60px; width: 100%"
              v-else
            >
              <div class="no-recommend-brand">
                <img src="@/assets/images/public/not-plandata.png">
                <p>暂无相关数据</p>
              </div>
            </div>
          </div>
        </div>
        <h3 class="second-title">品牌落位级次分布对比</h3>
        <div
          class="d-flex brand-distribute"
          v-if="brandDistribute.length === 2"
        >
          <template v-for="(item, inx) in brandDistribute">
            <div
              v-if="item.length > 0"
              class="brand-distribute-message"
              :key="inx"
            >
              <h4>{{inx === 0 ? '租决1' : '租决2'}}</h4>
              <ul class="d-flex common-block-list">
                <!-- <li
                  v-for="(item2, inx2) in item"
                  :key="inx2"
                >
                  <p class="statistics-title">{{item2.brandLevelType === 1 ? '标杆' : item2.brandLevelType === 2 ? '标准' : '非标'}}品牌落位统计</p>
                  <div class="percent">{{item2.brandLevelValue}}<i style="font-size: 16px">个</i></div>
                  <div class="d-flex">
                    <span>{{item2.brandGradeValue[0]}}<i class="leave">A级</i></span>
                    <span>{{item2.brandGradeValue[1]}}<i class="leave">B级</i></span>
                    <span>{{item2.brandGradeValue[2]}}<i class="leave">C级</i></span>
                    <span>{{item2.brandGradeValue[3]}}<i class="leave">D级</i></span>
                  </div>
                </li> -->
                <li
                  v-for="(item2, index) in item"
                  :key="index+item2.brandLevelType"
                >
                  <h4>{{item2.brandLevelType}}级品牌<span style="white-space: nowrap">目标占比</span></h4>
                  <p class="num">
                    {{item2.brandLevelValue}}<span>%</span>
                  </p>
                  <i class="iconfont">{{['&#xe6c5;','&#xe6c1;','&#xe6c4;','&#xe6c2;','&#xe6c3;'][index]}}</i>
                </li>
              </ul>
            </div>
            <div
              style="flex: 1"
              :key="inx"
              v-else
            >
              <div class="no-recommend-brand">
                <img src="@/assets/images/public/not-plandata.png">
                <p>暂无相关数据</p>
              </div>
            </div>
          </template>
        </div>
        <h3 class="second-title">品牌组合对比</h3>
        <ul class="brand-list d-flex f-wrap">
          <li
            v-for="(item, inx) in brandList1"
            :key="inx"
            :class="inx === brandInx ? 'active' : ''"
            @click="brandInx = inx"
          >{{item.industry}}</li>
        </ul>
        <div class="d-flex brand-contrast">
          <div
            class="brand-data is-scroll-y"
            v-if="brandList1.length > 0"
          >
            <p>租决1</p>
            <template v-for="(item, inx) in brandList1[brandInx].brandWithBuildings">
              <div
                :key="inx"
                :style="item.identical ? 'color: #FFF' : ''"
                class="brand-name"
              ><span @click="openBrand(item)">{{item.brandName + '【'+ item.buildings +'】'}}</span></div>
            </template>
          </div>
          <div
            style="padding-bottom: 60px; width: 401px; margin-right: 170px"
            v-else
          >
            <div class="no-recommend-brand">
              <img src="@/assets/images/public/not-plandata.png">
              <p>暂无相关数据</p>
            </div>
          </div>
          <div class="repeat-num"><span>重合品牌数</span>{{brandIdentical}}</div>
          <div
            class="brand-data w-is-scroll"
            v-if="brandList2.length > 0"
          >
            <p>租决2</p>
            <template v-for="(item, inx) in brandList2[brandInx].brandWithBuildings">
              <div
                :key="inx"
                :style="item.identical ? 'color: #FFF' : ''"
                class="brand-name"
              ><span @click="openBrand(item)">{{item.brandName + '【'+ item.buildings +'】'}}</span></div>
            </template>
          </div>
          <div
            style="padding-bottom: 60px; width: 401px"
            v-else
          >
            <div class="no-recommend-brand">
              <img src="@/assets/images/public/not-plandata.png">
              <p>暂无相关数据</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import echarts from 'echarts'
import { getCookie, toThousands } from '@/utils'
import WordCloud from 'vue-wordcloud'
import { mapGetters } from 'vuex'
export default {
  name: 'leasingComparison',
  data () {
    return {
      loading: false,
      filtroProject: null,
      projectOptions: [],
      chartComparison: null,
      originLeasingTitle: '',
      originDecision: null,
      leasingDecision: null,
      leasingDecisionList: [],
      overview: [],
      commercialValue: [],
      commercialData: [
        { name: '租决1业态面积', data: [] },
        { name: '租决2业态面积', data: [] },
        { name: '租决1业态面积占比', data: [] },
        { name: '租决2业态面积占比', data: [] }
      ],
      mainSecondlValue: [],
      mainSecondlData: [
        { name: '租决1主次力店面积', data: [] },
        { name: '租决2主次力店面积', data: [] },
        { name: '租决1主次力店面积占比', data: [] },
        { name: '租决2主次力店面积占比', data: [] }
      ],
      planlValue: [],
      planlData: [
        { name: '租决1', data: [] },
        { name: '租决2', data: [] },
        { name: '租决1业态租金占比', data: [] },
        { name: '租决2业态租金占比', data: [] }
      ],
      fallPlanlValue: [],
      fallPlanlData: [
        { name: '租决1', data: [] },
        { name: '租决2', data: [] }
      ],
      planContrastType: 1,
      vsDataShow: [true, true],
      fallPlan: 1,
      fallPlanList: [
        { label: '新兴品牌数量对比', value: 1 },
        { label: '连锁品牌数量对比', value: 2 }
      ],
      vsList: [],
      brandInx: 0,
      brandList1: [],
      brandList2: [],
      brandIdentical: 0,
      brandDistribute: [],
      brandDecisions: [],
      constitute1NotData: false,
      constitute2NotData: false,
      words: [],
      wordsHasdata: [false, false],
      fallPlanNotData: false
    }
  },
  components: {
    WordCloud
  },
  props: {
    projectId: {
      type: Number
    }
  },
  watch: {
    projectId (newValue, oldValue) {
      console.log('projectId', this.projectId)
    },
    brandInx (inx) {
      this.brandContrast(inx)
    }
  },
  beforeDestroy () {
    if (!this.chartComparison) {
      return
    }
    this.chartComparison.dispose()
    this.chartComparison = null
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  mounted () {
    this.originDecision = Number(this.$route.query.decision)
    this.getLeftData(this.originDecision)
    this.getDataProjList()
  },
  methods: {
    // 跳转企业品牌库
    openBrand (item) {
      window.open(this.$gaodeDomain + '/omsviews/rent/assets/enterprise?menuCode=11&tokenval=' + getCookie('wisdom_token') + '&brandId=' + item.brandId + '&gbid=' + item.gbid + '&brandName=' + item.brandName, '_blank')
    },
    getLeftData (id) {
      this.getOverview(id, 'left')
      this.getFloorIndustryPieAndLine(id, 'left')
      this.getCombination(id, 'left')
      this.getBerthPlanCard(id, 'left')
      this.getStoreBar(id, 'left')
      this.getBrandCompare(id, 'left')
      this.getLabelPortrait(id, 'left')
    },
    // 品牌标签画像对比
    getLabelPortrait (id, type) {
      this.loading = true
      const params = { decisionId: id || this.leasingDecision }
      this.axios.get(api.labelPortrait, { params: params }).then(res => {
        this.loading = false
        res.data.data.forEach(item => {
          item.value = item.num
        })
        const inx = type === 'left' ? 0 : 1
        this.wordsHasdata[inx] = res.data.data.length > 0
        this.$set(this.words, inx, res.data.data)
      })
    },
    // 获取品牌策略对比
    getBrandCompare (id, type) {
      this.loading = true
      const params = { decisionId: id || this.leasingDecision }
      this.axios.get(api.brandCompare, { params: params }).then(res => {
        this.loading = false
        const inx = type === 'left' ? 0 : 1
        this.$set(this.brandDecisions, inx, res.data.data)
        this.initBrandDecisionsChart(this.brandDecisions)
      })
    },
    // 获取业态策略对比
    getIndustryCompare () {
      this.loading = true
      var params = {
        decisionId: this.originDecision,
        compareDecisionId: this.leasingDecision
      }
      this.axios.get(api.industryCompare, { params: params }).then(res => {
        this.loading = false
        const data = res.data.data
        this.commercialValue = data.labels
        this.commercialData.forEach((item, inx) => {
          item.data = data.series[inx].data
        })
        this.initContrastChart('commercialContrast', this.commercialValue, this.commercialData)
      })
    },
    // 获取主次力店策略对比
    getStoreBar (id, type) {
      this.loading = true
      const params = { decisionId: id || this.leasingDecision }
      this.axios.get(api.getStoreBar, { params: params }).then(res => {
        this.loading = false
        const data = res.data.data
        this.mainSecondlValue = data.labels
        if (type === 'left') {
          this.mainSecondlData[0].data = data.series[0].data
          this.mainSecondlData[2].data = data.series[1].data
        } else {
          this.mainSecondlData[1].data = data.series[0] ? data.series[0].data : []
          this.mainSecondlData[3].data = data.series[1] ? data.series[1].data : []
        }
        this.initContrastChart('mainSecondContrast', this.mainSecondlValue, this.mainSecondlData)
      })
    },
    // 获取品牌落位级次分布
    getBerthPlanCard (id, type) {
      this.loading = true
      const params = { decisionId: id || this.leasingDecision }
      this.axios.get(api.berthPlanCard, { params: params }).then(res => {
        this.loading = false
        const inx = type === 'left' ? 0 : 1
        this.$set(this.brandDistribute, inx, res.data.data)
      })
    },
    // 获取品牌组合对比
    getCombination (id, type) {
      this.loading = true
      const params = { decisionId: id || this.leasingDecision }
      this.axios.get(api.combination, { params: params }).then(res => {
        this.loading = false
        if (type === 'left') {
          this.brandList1 = res.data.data
        } else {
          this.brandList2 = res.data.data
        }
        this.brandContrast(this.brandInx)
      })
    },
    // 获取数据总览
    getOverview (id, type) {
      this.loading = true
      const params = { decisionId: id || this.leasingDecision }
      this.axios.get(api.overview, { params: params }).then(res => {
        this.loading = false
        const inx = type === 'left' ? 0 : 1
        const data = res.data.data
        data.rent = toThousands(data.rent)
        data.cost = toThousands(data.cost)
        data.income = toThousands(data.income)
        this.$set(this.overview, inx, data)
      })
    },
    // 获取楼层业态构成
    getFloorIndustryPieAndLine (id, type) {
      this.loading = true
      const params = { decisionId: id || this.leasingDecision }
      this.axios.get(api.floorIndustryPieAndLine, { params: params }).then(res => {
        this.loading = false
        if (type === 'left') {
          if (res.data.data.series.length === 0) {
            this.constitute1NotData = true
          } else {
            this.constitute1NotData = false
            this.constitute('constitute1', res.data.data)
          }
        } else {
          if (res.data.data.series.length === 0) {
            this.constitute2NotData = true
          } else {
            this.constitute2NotData = false
            this.$nextTick(() => {
              this.constitute('constitute2', res.data.data)
            })
          }
        }
      })
    },
    // 获取租金计划柱状图及条形图
    getRentPlanHistogramAndBar () {
      this.loading = true
      const params = {
        decisionId: this.originDecision,
        compareDecisionId: this.leasingDecision,
        type: this.planContrastType
      }
      this.axios.get(api.rentPlanHistogramAndBar, { params: params }).then(res => {
        this.loading = false
        const histogram = res.data.data.histogram
        const bar = res.data.data.bar
        bar.series.map((item, inx) => {
          const arr = []
          item.data.map(item2 => {
            let num = null
            num = item2 === '-' ? 0 : Number(item2)
            arr.push(num)
          })
          this.$set(this.vsList, inx + 1, arr)
        })
        if (this.planContrastType === 1) {
          this.planlData[2].name = '租决1楼层租金占比'
          this.planlData[3].name = '租决2楼层租金占比'
        } else {
          this.planlData[2].name = '租决1业态租金占比'
          this.planlData[3].name = '租决2业态租金占比'
        }
        this.planlValue = histogram.labels
        this.$set(this.vsList, 0, bar.labels)
        this.planlData[0].data = histogram.series[0].data
        this.planlData[2].data = histogram.series[0].rate
        this.planlData[1].data = histogram.series[1].data
        this.planlData[3].data = histogram.series[1].rate
        this.initContrastChart('planContrast', this.planlValue, this.planlData)
      })
    },
    // 新兴/连锁品牌对比
    getHistogram () {
      this.loading = true
      const params = {
        decisionId: this.originDecision,
        compareDecisionId: this.leasingDecision,
        newlyOrChain: this.fallPlan
      }
      this.axios.get(api.histogram, { params: params }).then(res => {
        this.loading = false
        const data = res.data.data
        if (data.series.length === 0) {
          this.fallPlanNotData = true
        } else {
          this.fallPlanNotData = false
          this.fallPlanlValue = data.labels
          this.fallPlanlData[0].data = data.series[0].data
          this.fallPlanlData[0].rate = data.series[0].rate
          this.fallPlanlData[1].data = data.series[1].data
          this.fallPlanlData[1].rate = data.series[1].rate
          this.$nextTick(() => {
            this.initContrastChart('fallPlanContrast', this.fallPlanlValue, this.fallPlanlData)
          })
        }
      })
    },
    // select项目列表
    getDataProjList () {
      this.loading = true
      this.axios.post(api.getBerthProjectList).then(res => {
        this.loading = false
        if (res.data.code === 0) {
          this.projectOptions = res.data.data
          this.filtroProject = Number(this.$route.query.project)
          this.getDecisionsList('creat')
        }
      })
    },
    // 获取decisionId
    getDecisionsList (type) {
      this.loading = true
      this.axios.get(api.decisions, { params: { projectId: this.filtroProject } }).then(res => {
        this.loading = false
        if (res.data.code === 0) {
          this.brandIdentical = 0
          const resData = res.data.data
          if (resData && resData.length) {
            this.leasingDecisionList = resData
            if (type === 'creat') {
              // 对比数据 如果传进来的id是最大的拿前一个id对比，否则拿下一个id对比
              resData.map(item => {
                const id = item.decisionId
                if (id > this.originDecision && id !== this.originDecision && !this.leasingDecision) {
                  this.leasingDecision = id
                }
              })
              if (this.leasingDecision === null) {
                this.leasingDecision = resData[resData.length - 2].decisionId
              }
            }
            resData.map(item => {
              if (item.decisionId === this.originDecision) {
                this.originLeasingTitle = item.decisionName
              }
            })
            this.getOverview()
            this.getFloorIndustryPieAndLine()
            this.getRentPlanHistogramAndBar()
            this.getCombination()
            this.getBerthPlanCard()
            this.getHistogram()
            this.getStoreBar()
            this.getIndustryCompare()
            this.getBrandCompare()
            this.getLabelPortrait()
          } else {
            this.leasingDecisionList = []
          }
        }
      })
    },
    // 品牌对比
    brandContrast (inx) {
      const brand1 = (this.brandList1[inx] && this.brandList1[inx].brandWithBuildings) || []
      const brand2 = (this.brandList2[inx] && this.brandList2[inx].brandWithBuildings) || []
      this.brandIdentical = 0
      brand1.forEach(item => {
        item.identical = false
        brand2.forEach(item2 => {
          if (item.brandName === item2.brandName) {
            item.identical = true
            item2.identical = true
            this.brandIdentical += 1
          }
        })
      })
    },
    // 租金计划对比 业态/楼层变更
    planContrastChange () {
      this.getRentPlanHistogramAndBar()
    },
    // 落位计划-新兴/连锁变更
    fallPlanChange () {
      this.getHistogram()
    },
    // 租赁决策变更
    leasingChange () {
      this.getDecisionsList()
    },
    // 品牌策略对比图表
    initBrandDecisionsChart (data) {
      const labelsList = data[0].labels.map(item => {
        return item + '级'
      }).reverse()
      const series = []
      const colors = [
        ['#8cae9c', '#8cae9c99'],
        ['#5ba9f6', '#5ba9f691'],
        ['#66da7c', '#66da7c85'],
        ['#e871ae', '#de83b18c'],
        ['#346ce1', '#557fd985']
      ]
      labelsList.map((item, index) => {
        for (let idx = 0; idx < 2; idx++) {
          const content = {
            barWidth: 24,
            color: colors[Math.abs(index - 4)][idx],
            data: [0, 0, 0, 0, 0],
            name: item,
            stack: 'stack1',
            type: 'bar'
          }
          let num = 0
          if (data[idx]) {
            num = idx ? parseFloat(data[idx].series[0].data[Math.abs(index - 4)]) : (0 - parseFloat(data[idx].series[0].data[Math.abs(index - 4)]))
          }
          content.data[index] = num
          series.push(content)
        }
      })
      // data.map((item, inx) => {
      //   item.series.map((item2, inx2) => {
      //     const seriesData = []
      //     item2.data.map((item3, inx3) => {
      //       let itemData = 0
      //       if (inx2 !== 0) {
      //         itemData = inx === 0 ? -(Number(item3) * Number(item.series[0].data[inx3]) / 100) : Number(item3) * Number(item.series[0].data[inx3]) / 100
      //       } else {
      //         itemData = inx === 0 ? -(Number(item3)) : Number(item3)
      //       }
      //       seriesData.push(itemData)
      //     })
      //     const obj = {
      //       name: inx === 0 && inx2 === 0 ? '租决1' : inx === 1 && inx2 === 0 ? '租决2' : item2.name,
      //       type: 'bar',
      //       barWidth: 24,
      //       stack: '总量',
      //       data: seriesData
      //     }
      //     if (inx2 !== 0) {
      //       obj.barWidth = 10
      //       obj.stack = 'stack1'
      //       obj.barGap = 0
      //     }
      //     series.push(obj)
      //   })
      // })
      this.chartComparison = echarts.init(document.getElementById('brandContrast'))
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' },
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          extraCssText: 'box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.9)',
          // formatter: (params) => {
          //   let res = ''
          //   for (let i = 0; i < params.length; i++) {
          //     const item = params[i]
          //     if (i === 0 || i === 5) {
          //       res += '<div style="margin-bottom: 3px; font-size: 12px; padding: 0 7px">' + item.marker + '<span style="color: #808191">' + item.seriesName + '：</span>' + Math.abs(item.data) + '% </div>'
          //     } else {
          //       const data = Math.abs(item.data) === 0 ? 0 : Math.round(Math.abs(item.data) / Math.abs(params[0].data) * 100 * 100) / 100
          //       res += '<div style="margin-bottom: 3px; font-size: 12px; padding: 0 7px">' + item.marker + '<span style="color: #808191">' + item.seriesName + '：</span>' + data + '% </div>'
          //     }
          //   }
          //   return res
          // }
          formatter: (params) => {
            let res = ''
            let num = 0
            for (let i = 0; i < params.length; i++) {
              if (params[i].axisValue === params[i].seriesName) {
                ++num
                res += '<h5>租决' + num + '-' + params[i].name + '</h5>' + '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:' + params[i].color + '"></span><span style="color: #61616d;font-size: 12px">' + params[i].name + '品牌数量占比: </span>' + Math.abs(params[i].data) + '%'
                if (num === 2) break
              }
            }
            return res
          }
        },
        legend: {
          itemGap: 16,
          itemWidth: 14,
          itemHeight: 8,
          textStyle: {
            color: '#808191',
            fontSize: 12,
            padding: [3, 0, 0, 0]
          },
          bottom: 0
        },
        grid: {
          top: '0',
          left: '0',
          right: '6%',
          bottom: '6%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          name: '数量占比',
          min: -100,
          max: 100,
          splitNumber: 10,
          splitLine: {
            lineStyle: {
              color: ['rgba(226,226,226,.1'],
              width: 1
            }
          },
          axisLabel: {
            color: '#808191',
            formatter: function (value) {
              if (value < 0) return -value + '%'
              else return value + '%'
            }
          },
          nameTextStyle: {
            color: '#808191',
            padding: [0, 0, -27, 10]
          }
        },
        yAxis: {
          axisLabel: { color: '#808191' },
          data: labelsList,
          type: 'category'
        },
        color: ['#00D7D5', '#00C567', '#1890FF', '#ee6666', '#73c0de', '#fc8452', '#3ba272', '#9a60b4', '#ea7ccc'],
        series: series
      }
      this.chartComparison.setOption(option)
    },
    // 饼图折线
    constitute (id, data) {
      const chart = echarts.init(document.getElementById(id))
      const source = []
      data.series.map((item, inx) => {
        source.push(item.data)
        source[inx].unshift(item.name)
      })
      source.unshift(data.labels)
      source[0].unshift('product')
      chart.setOption({
        grid: {
          top: '55%',
          bottom: 0,
          containLabel: true
        },
        legend: {
          top: '3%',
          itemWidth: 8,
          itemHeight: 8,
          icon: 'circle',
          textStyle: {
            color: '#808191',
            fontSize: 14,
            padding: [1, 0, 0, 0]
          }
        },
        tooltip: {
          trigger: 'axis',
          showContent: false,
          backgroundColor: 'rgba(0, 0, 0, 0.85)'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLine: {
            lineStyle: { color: '#373A43' }
          },
          axisLabel: {
            textStyle: { color: '#808191' }
          }
        },
        yAxis: {
          name: '业态面积/㎡',
          gridIndex: 0,
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#373A43'
            }
          },
          axisLine: {
            lineStyle: { color: '#373A43' }
          },
          axisLabel: {
            textStyle: { color: '#808191' }
          },
          nameTextStyle: { color: '#808191' }
        },
        dataset: {
          source: source
        },
        series: [
          { type: 'line', smooth: true, seriesLayoutBy: 'row', color: '#1890FF' },
          { type: 'line', smooth: true, seriesLayoutBy: 'row', color: '#5C61B6' },
          { type: 'line', smooth: true, seriesLayoutBy: 'row', color: '#00C567' },
          { type: 'line', smooth: true, seriesLayoutBy: 'row', color: '#00D7D5' },
          {
            type: 'pie',
            id: 'pie',
            color: ['#1890FF', '#5C61B6', '#00C567', '#00D7D5'],
            radius: '25%',
            center: ['50%', '30%'],
            label: {
              formatter: (item) => {
                return item.data[0] + ' ' + item.data[1] + '㎡' + '（' + item.percent + '%）'
              }
            },
            encode: {
              itemName: 'product',
              value: source[0][1]
            }
          }
        ]
      }, true)
      chart.on('updateAxisPointer', function (event) {
        const xAxisInfo = event.axesInfo[0]
        if (xAxisInfo) {
          const inx = xAxisInfo.value + 1
          chart.setOption({
            series: {
              id: 'pie',
              label: {
                formatter: (item) => {
                  return item.data[0] + ' ' + item.data[inx] + '㎡' + '（' + item.percent + '%）'
                }
              },
              encode: {
                itemName: 'product',
                value: inx
              }
            }
          })
        }
      })
    },
    // 折线柱状混合图表
    initContrastChart (id, xValue, data) {
      if (!document.getElementById(id)) {
        return
      }
      const legend = []
      const series = []
      const color = ['#00C567', '#00D7D5', '#F89407', '#1890FF']
      const yAxis = [{
        type: 'value',
        name: id === 'planContrast' ? '租金/万元' : id === 'fallPlanContrast' ? '品牌数量' : id === 'mainSecondContrast' ? '主次力店面积' : '业态面积/㎡',
        splitLine: {
          show: id === 'fallPlanContrast',
          lineStyle: {
            type: 'dashed',
            color: '#373A43'
          }
        },
        axisLine: {
          lineStyle: { color: '#373A43' }
        },
        axisLabel: {
          textStyle: { color: '#808191' }
        },
        nameTextStyle: { color: '#808191' }
      }]
      if (id !== 'fallPlanContrast') {
        yAxis.push({
          type: 'value',
          name: id === 'planContrast' ? '租金占比' : '面积占比',
          min: 0,
          max: 100,
          interval: 20,
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#373A43'
            }
          },
          axisLine: {
            lineStyle: { color: '#373A43' }
          },
          axisLabel: {
            textStyle: { color: '#808191' },
            formatter: '{value} %'
          },
          nameTextStyle: { color: '#808191' }
        })
      }
      data.map((item, inx) => {
        console.log(item)
        legend.push({
          name: item.name,
          icon: inx < 2 ? 'rect' : 'circle'
        })
        const obj = {
          name: item.name,
          type: 'bar',
          itemStyle: {
            normal: { color: color[inx] }
          },
          barWidth: 24,
          data: item.data
        }
        if (inx > 1) {
          obj.type = 'line'
          obj.smooth = true
          obj.showSymbol = false
          obj.yAxisIndex = 1
        }
        series.push(obj)
      })
      const chart = echarts.init(document.getElementById(id))
      chart.setOption({
        grid: {
          top: '7%',
          left: '1%',
          right: '0',
          bottom: '7%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0, 0, 0, .85)',
          extraCssText: 'box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.9)',
          axisPointer: { type: 'shadow' },
          formatter: (chartData) => {
            let text = '<div style="margin-bottom: 3px; font-size: 12px; padding-left: 15px; color: #B2B3BD">' + chartData[0].axisValue + '</div>'
            const name = ['租赁1面积', '租赁2面积', '租赁1面积占比', '租赁2面积占比']
            chartData.map((item, inx) => {
              let text2 = ''
              if (id !== 'fallPlanContrast') {
                if (inx < 2) {
                  if (id === 'planContrast') {
                    text2 = '万'
                  } else {
                    text2 = '㎡'
                  }
                } else {
                  text2 = '%'
                }
              }
              if (id === 'mainSecondContrast' || id === 'commercialContrast') {
                text += '<div style="margin-bottom: 3px; font-size: 12px; padding: 0 3px">' + item.marker + '<span style="color: #808191">' + name[inx] + '：</span>'
              } else {
                text += '<div style="margin-bottom: 3px; font-size: 12px; padding: 0 3px">' + item.marker + '<span style="color: #808191">' + item.seriesName + '：</span>'
              }
              text += item.data + text2
              if (data[inx].rate) {
                text += '<span style="margin-left: 15px">' + data[inx].rate[chartData[0].dataIndex] + '%</span>'
              }
              text += '</div>'
            })
            return text
          }
        },
        legend: [{
          x: 'center',
          bottom: '0',
          itemWidth: 14,
          itemHeight: 8,
          textStyle: {
            color: '#808191',
            fontSize: 12,
            padding: [3, 0, 0, 0]
          },
          data: legend
        }],
        yAxis: yAxis,
        xAxis: {
          type: 'category',
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#373A43'
            }
          },
          axisLine: {
            lineStyle: { color: '#373A43' }
          },
          axisLabel: {
            textStyle: { color: '#808191' }
          },
          data: xValue
        },
        series: series
      })
    }
  }
}
</script>

<style lang="stylus">
.leasing-comparison
  .common-shadow-panel
    .el-radio__inner
      width 16px
      height 16px
      border none !important
      background rgba(255, 255, 255, 0.33)
    .is-checked
      .el-radio__inner
        background #F89407 !important
      .el-radio__label
        color #FFF !important
    .el-radio__label
      color #808191
      padding-left 6px
    .el-radio__inner::after
      width 10px
      height 10px
  .project-select
    .el-input.is-disabled .el-input__inner
      color #808191 !important
      border none !important
      height 36px
      line-height 36px
  .compare-project
    .native-input-solid
      width 360px
      .el-select
        background none
      .el-input--small .el-input__inner
        height 32px !important
        background #1E1E23 !important
        border 1px solid #666667 !important
  .fall-plan-select
    .el-input__inner
      width 204px
      color #808191
      background #373A43 !important
  .vs-list
    width 35.7%
    li
      margin-bottom 23px
    .vs-content
      span
        font-size 11px
        color #B2B3BD
        width 33%
    .vs-progress
      margin-top 11px
      .el-progress
        width 50%
        .el-progress-bar__outer
          background #373A43
    .left-progress
      .el-progress-bar__outer
        border-top-right-radius 0
        border-bottom-right-radius 0
        .el-progress-bar__inner
          left unset
          right 0
          border-top-right-radius 0
          border-bottom-right-radius 0
    .right-progress
      .el-progress-bar__outer
        border-top-left-radius 0
        border-bottom-left-radius 0
        .el-progress-bar__inner
          border-top-left-radius 0
          border-bottom-left-radius 0
    .vs-bottom
      justify-content center
      font-size 12px
      color #808191
      span
        cursor pointer
        i
          display inline-block
          width 14px
          height 8px
          border-radius 2px
          background #00C567
          margin-right 4px
      span:last-child
        margin-left 16px
        i
          background #00D7D5
      .unshow
        color #CCC
        i
          background #CCC !important
</style>
<style lang="stylus" scoped>
.leasing-comparison
  .cloud-box
    padding 24px 78px 0
    justify-content space-between
  .w-crumbs-href
    padding 0 24px
    margin 22px 0
    span
      color #808191
  .second-title
    margin-top 32px
  .second-title:before
    content ''
    display inline-block
    width 4px
    height 14px
    background #f89407
    vertical-align middle
    position relative
    bottom 1px
    margin-right 4px
  .f-wrap
    flex-wrap wrap
  .d-flex
    display flex
  .space-between
    justify-content space-between
  #brandContrast, #commercialContrast, #mainSecondContrast, #constitute1, #constitute2, #fallPlanContrast
    width 100%
    height 500px
  #planContrast
    width 57%
    height 418px
  #constitute1, #constitute2
    height 630px
  .common-shadow-panel
    width auto
    margin 25px
    .brand-list
      margin 24px 0 20px
      padding-left 6px
      li
        cursor pointer
        padding 7px 38px
        margin 0 16px 12px 0
        border-radius 2px
        border 1px solid #939498
      li.active
        color #FFA134
        border-color #FFA134
    .brand-contrast
      position relative
      justify-content center
      .brand-data
        width 401px
        height 259px
        overflow auto
        font-size 14px
        background #3A3C42
        margin-right 170px
        border-radius 2px
        box-shadow 0px 6px 30px rgba(0, 0, 0, 0.3)
        .brand-name
          span
            cursor pointer
          span:hover
            color #fff
            border-bottom 1px solid #fff
        div, p
          color #808191
          padding 13px
          border-bottom 1px solid #4B4D52
        p
          color #B2B3BD
      .brand-data:last-child
        margin 0
      .repeat-num
        position absolute
        bottom 0
        font-size 24px
        height 96px
        padding 13px 22px 29px
        text-align center
        background linear-gradient(180deg, #5D4731 0%, rgba(93, 71, 49, 0) 100%)
        span
          display block
          font-size 14px
          margin-bottom 5px
    .constitute
      margin-top 32px
      padding 32px
      border-radius 2px
      border 1px solid #383A41
      box-shadow 0px 6px 30px rgba(0, 0, 0, 0.5)
    .constitute>div
      width 50%
      text-align center
      .constitute-title
        font-weight bold
    .chart-data
      padding 60px 53px 32px
    .type-select, .fall-plan-select
      padding 17px 60px
      text-align right
    .other-data
      padding 0 43px 32px
    .brand-distribute
      margin-top 24px
      padding 0 6px
      justify-content space-between
      .brand-distribute-message:first-child
        margin-right 33px
      .brand-distribute-message
        border 1px solid #373A43
        font-weight bold
        flex 1
        height 100%
        &>h4
          font-size 16px
          font-weight bold
          text-align center
          padding 21px 0
          border-bottom 1px solid #373A43
        li
          flex 1
          padding 14px
          border-right 1px solid #373A43
          margin 0
          height 100%
          min-height 125px
          max-height 145px
        li:last-child
          border none
          .statistics-title:before
            background #00D7D5
        li:first-child
          .statistics-title:before
            background #37ABFD
  .vs-header
    padding 44px 3.2% 40px 3.2%
    margin 12px 16px
    box-sizing border-box
    .vs-icon
      display block
      line-height 230px
      text-align center
    .vs-icon:before
      content 'VS'
      font-size 26px
      font-family PingFang SC
      font-weight bold
      line-height 70px
      text-align center
      color #F89407
      display inline-block
      width 70px
      border 2px solid #F89407
      border-radius 50%
    .this-project, .compare-project
      width 42.3%
      h3
        font-size 18px
        font-family PingFang SC
        font-weight bold
        color #FFFFFF
        line-height 32px
      ul
        margin-top 19px
        overflow hidden
        display flex
        li
          margin-right 12px
          margin-bottom 16px
          height 140px
          display inline-block
          background linear-gradient(180deg, #FFAA41 0%, #FF8239 100%)
          border-radius 2px
          padding 2.7%
          box-sizing border-box
          position relative
          overflow hidden
          flex 1
          &:before
            content ''
            height 0
            width calc(100% - 40px)
            margin 0
            border-bottom 1px #fff dashed
            position absolute
            bottom 51px
          h4
            font-size 22px
            font-family PingFang SC
            font-weight 400
            color #FFFFFF
          p
            margin 6px 0 19px 0
            color #FFFFFF
            display inline-block
        li:last-child
          margin-right 0
      p
        color #808191
        font-size 14px
@media screen and (max-width 1760px)
  .leasing-comparison
    .vs-header
      padding 44px 2.2% 40px 2.2%
      .this-project, .compare-project
        width 45.3%
        li
          padding 2.7% 1.5% !important
</style>
